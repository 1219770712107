@font-face {
    font-family: "Open Sans";
    src: local("serif"), url("fonts/OpenSans-VariableFont_wdth_wght.ttf");
    font-weight: 1 999;
    /*Show system Font until other font is loaded*/
    font-display: swap;
}

@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
